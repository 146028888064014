import React from "react";
import { useState, useEffect } from "react";
import "../styles/App.css";

import diagonalArrow from "../icons/diagonal-arrow.svg";

import headshot from "../images/headshot4.jpg";
import sirinThada from "../images/sirin-thada-tv.jpg";
import kuang from "../images/kuang.png";
import riedel from "../images/riedel.png";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import RSVPForm from "../components/RSVPForm";
import BasicInfo from "../components/BasicInfo";
import Bio from "../components/Bio";
import Pubs from "../components/Pubs";

function Home() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [data, setData] = useState(null);

    useEffect(() => {
        fetch("/api")
            .then((res) => res.json())
            .then((data) => setData(data.message));
    }, []);

    const postAPreciousStone = (
        <div className="post">
            <text className="postTitle">"A Precious Stone"</text>
            <div className="paragraph">
                <text>
                    <i>Catapult</i>
                </text>
                <br />
                <text>December 2022</text>
            </div>
            <br />
            <div className="photoColumn">
                <a href="https://magazine.catapult.co/fiction/stories/amy-y-q-lin-fiction-short-story-a-precious-stone" target="_blank" rel="noopener noreferrer">
                    <img src={sirinThada} className="postPhoto" alt="sirinThada" />
                </a>
                <text className={"photoCredit"}>Illustration by Sirin Thada for Catapult</text>
            </div>
            <div className="paragraph">
                <text>
                    <i>A humble name. Shi Tou cannot be mistaken for a precious stone. To be something ordinary makes you less fragile.</i>&nbsp;
                    <a className="linkNoUnderline" href="https://magazine.catapult.co/fiction/stories/amy-y-q-lin-fiction-short-story-a-precious-stone" target="_blank" rel="noopener noreferrer">
                        ...more
                    </a>
                </text>
            </div>
        </div>
    );

    const postRFKuang = (
        <div className="post">
            <text className="postTitle">"Very Little of This Book is Made-Up: Talking With R.F. Kuang About Her Novel"</text>
            <div className="paragraph">
                <text>
                    <i>The Rumpus</i>
                </text>
                <br />
                <text>May 2023</text>
            </div>
            <br />
            <div className="photoColumn">
                <a href="https://therumpus.net/2023/05/17/very-little-of-this-book-is-made-up-talking-with-r-f-kuang-about-her-novel/" target="_blank" rel="noopener noreferrer">
                    {/* <div className="photoRow">
                        <img src={yellowface} className="postPhotoInRow" alt="yellowface" />
                        <img src={rfKuang} className="postPhotoInRow" alt="rfKuang" />
                    </div> */}
                    <img src={kuang} className="postPhoto" alt="rfKuang" />
                </a>
                <text className={"photoCredit"}>Author photo by John Packman</text>
            </div>
            <div className="paragraph">
                <text>
                    <i>I don't think there are easy answers. Should we make judgments about rudeness when we talk about artistic freedom?</i>&nbsp;
                    <a
                        className="linkNoUnderline"
                        href="https://therumpus.net/2023/05/17/very-little-of-this-book-is-made-up-talking-with-r-f-kuang-about-her-novel/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        ...more
                    </a>
                </text>
            </div>
        </div>
    );

    const postJoshRiedel = (
        <div className="post">
            <text className="postTitle">"Accessing the Truth: An Interview with Josh Riedel"</text>
            <div className="paragraph">
                <text>
                    <i>One Story</i>
                </text>
                <br />
                <text>March 2023</text>
            </div>
            <br />
            <div className="photoColumn">
                <a href="https://one-story.com/2023/03/16/accessing-the-truth-an-interview-with-josh-riedel/" target="_blank" rel="noopener noreferrer">
                    {/* <div className="photoRow">
                        <img src={pleaseReport} className="postPhotoInRow" alt="pleaseReport" />
                        <img src={joshRiedel} className="postPhotoInRow" alt="joshRiedel" />
                    </div> */}
                    <img src={riedel} className="postPhoto" alt="riedel" />
                </a>
                <text className={"photoCredit"}>Author photo by Andrew Owen</text>
            </div>
            <div className="paragraph">
                <text>
                    <i>A lot of my work asks, How can we truly know another person?</i>&nbsp;
                    <a className="linkNoUnderline" href="https://one-story.com/2023/03/16/accessing-the-truth-an-interview-with-josh-riedel/" target="_blank" rel="noopener noreferrer">
                        ...more
                    </a>
                </text>
            </div>
        </div>
    );

    const postDivider = <div className="postDivider" />;

    return (
        <div className="Home">
            <div className="aboutMe">
                {
                    <>
                        <div className="column">
                            <img src={headshot} className="headshot" alt="headshot" />
                            <div className="photoCreditRow">
                                <text className={"photoCredit"}>Photo courtesy of&nbsp;</text>
                                <div>
                                    <a className="photoCreditLink" href="https://www.instagram.com/maxituyip" target="_blank" rel="noopener noreferrer">
                                        Maxi Tu Yip
                                    </a>
                                    <img src={diagonalArrow} className="diagonalArrow" alt="diagonalArrow" />
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <BasicInfo />
                            {/* <p>{!data ? "Loading..." : data}</p> */}
                            <br />
                            <Bio short={true} />
                        </div>
                    </>
                }

                {/* <RSVPForm /> */}
            </div>

            <Pubs />

            <div className="container">
                {postDivider}
                {postAPreciousStone}
                {postDivider}
                {postRFKuang}
                {postDivider}
                {postJoshRiedel}
            </div>
        </div>
    );
}

export default Home;
