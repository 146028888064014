import diagonalArrow from "../icons/diagonal-arrow.svg";

// eslint-disable-next-line no-useless-concat
var eDone = "amyy" + "qlin" + "@" + "gm" + "ail." + "com";
// eslint-disable-next-line no-useless-concat
var eProtocol = "mai" + "lto" + ":";

const Socials = () => {
    return (
        <div className="socialsRow">
            <div className="linkWithArrow">
                <a className="socialLink" href={eProtocol + eDone} target="_blank" rel="noopener noreferrer">
                    Email
                </a>
                <img src={diagonalArrow} className="diagonalArrow" alt="diagonalArrow" />
            </div>
            <div className="linkWithArrow">
                <a className="socialLink" href="https://linkedin.com/in/amyliny" target="_blank" rel="noopener noreferrer">
                    LinkedIn
                </a>
                <img src={diagonalArrow} className="diagonalArrow" alt="diagonalArrow" />
            </div>
            <div className="linkWithArrow">
                <a className="socialLink" href="https://instagram.com/ammkylin" target="_blank" rel="noopener noreferrer">
                    Instagram
                </a>
                <img src={diagonalArrow} className="diagonalArrow" alt="diagonalArrow" />
            </div>
        </div>
    );
};

export default Socials;
