const Pubs = () => {
    return (
        <div className="pubsAndSocials">
            <text>
                <p>Fiction:</p>
                <ul>
                    <li className="bullet">
                        "A Precious Stone," a short story in&nbsp;
                        <a className="link" href="https://magazine.catapult.co/fiction/stories/amy-y-q-lin-fiction-short-story-a-precious-stone" target="_blank" rel="noopener noreferrer">
                            <i>Catapult</i>
                        </a>{" "}
                        (Dec 2022)
                    </li>
                </ul>
                <p>Interviews and Criticism:</p>
                <ul>
                    <li className="bullet">
                        "Very Little of This Book Is Made-up: Talking with R.F. Kuang about her Novel," an interview in&nbsp;
                        <a
                            className="link"
                            href="https://therumpus.net/2023/05/17/very-little-of-this-book-is-made-up-talking-with-r-f-kuang-about-her-novel/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i>The Rumpus</i>
                        </a>
                        &nbsp;(May 2023)
                    </li>
                    <li className="bullet">
                        "Accessing the Truth: An Interview with Josh Riedel," an interview in&nbsp;
                        <a className="link" href="https://one-story.com/2023/03/16/accessing-the-truth-an-interview-with-josh-riedel/" target="_blank" rel="noopener noreferrer">
                            <i>One Story</i>
                        </a>
                        &nbsp;(Mar 2023)
                    </li>
                    <li className="bullet">
                        "In This Corner of the World is a Simple, Perfect Exhibit of Life," a film review in&nbsp;
                        <a className="link" href="https://cornellsun.com/2017/11/05/in-this-corner-of-the-world-is-a-simple-perfect-exhibit-of-life/" target="_blank" rel="noopener noreferrer">
                            <i>The Cornell Daily Sun</i>
                        </a>{" "}
                        (November 2017)
                    </li>
                    <li className="bullet">
                        "A Lost French Classic Remembered," an animated film review in <i>The Cornell Daily Sun</i> (2017)
                    </li>
                    <li className="bullet">
                        "Not Strange At All," a music review in&nbsp;
                        <a className="link" href="https://cornellsun.com/2016/08/30/not-strange-at-all/" target="_blank" rel="noopener noreferrer">
                            <i>The Cornell Daily Sun</i>
                        </a>{" "}
                        (August 2016)
                    </li>
                    <li className="bullet">
                        "Stardew Valley: Pushing The Boundaries of Farming RPGs," a video game review in&nbsp;
                        <a className="link" href="https://cornellsun.com/2016/02/23/stardew-valley-pushing-the-boundaries-of-farming-rpgs/" target="_blank" rel="noopener noreferrer">
                            <i>The Cornell Daily Sun</i>
                        </a>{" "}
                        (February 2016)
                    </li>
                    <li className="bullet">
                        "Appreciating Miyazaki: Insight Into a Master's World," a documentary review in <i>The Cornell Daily Sun</i> (2015)
                    </li>
                    <li className="bullet">
                        "Movie Review: The Maze Runner," a film review in <i>The Cornell Daily Sun</i> (2014)
                    </li>
                    <li className="bullet">
                        "Spinning Singles," a music review in <i>The Cornell Daily Sun</i> (2014)
                    </li>
                    <li className="bullet">
                        "Cover Boy: An Uncensored Observation on LGBTQ Struggles," a dance review in <i>The Cornell Daily Sun</i> (2014)
                    </li>
                </ul>
                <p>Content Marketing:</p>
                <ul>
                    <li className="bullet">
                        "Video Game Testers Hiring Guide," an article in{" "}
                        <a className="link" href="https://www.toptal.com/video-game-testers#hiring-guide" target="_blank" rel="noopener noreferrer">
                            Toptal's Engineering Blog
                        </a>{" "}
                        (September 2024)
                    </li>
                    <li className="bullet">
                        Copy for{" "}
                        <a className="link" href="https://www.toptal.com/web" target="_blank" rel="noopener noreferrer">
                            Web
                        </a>{" "}
                        and{" "}
                        <a className="link" href="https://www.toptal.com/react" target="_blank" rel="noopener noreferrer">
                            React
                        </a>{" "}
                        pages in Toptal's Engineering Blog (August 2024)
                    </li>
                </ul>
            </text>
            <br />
        </div>
    );
};

export default Pubs;
