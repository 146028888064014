import TimelineComponent from "../components/Timeline";

function Timeline() {
    return (
        <div className="Timeline">
            <div className="aboutMe">
                <div className="column">
                    <TimelineComponent />
                </div>
            </div>
        </div>
    );
}

export default Timeline;
