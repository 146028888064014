import Socials from "../components/Socials";

const Contact = () => {
    return (
        <div className="Contact">
            {/* <div className="container"> */}
            <div className="socials">
                <text className="postTitle">Contact me</text>
                <br />
                <br />
                <div className="paragraph">
                    <text>If you want to drop a heartfelt letter or share an exciting opportunity, you can reach me at amyyqlin (at) gmail (dot) com.</text>
                </div>
            </div>
            <div className="socialsDivider" />
            <div className="socials">
                <Socials />
            </div>
            {/* </div> */}
        </div>
    );
};

export default Contact;
