import diagonalArrow from "../icons/diagonal-arrow.svg";

import BioAndSocials from "../components/Bio";
// import BasicInfo from "../components/BasicInfo";
import headshot from "../images/headshot4.jpg";

function About() {
    return (
        <div className="Home">
            <div className="aboutMe">
                <div className="column">
                    <img src={headshot} className="headshot" alt="headshot" />
                    <div className="photoCreditRow">
                        <text className={"photoCredit"}>Photo courtesy of&nbsp;</text>
                        <div>
                            <a className="photoCreditLink" href="https://www.instagram.com/maxituyip" target="_blank" rel="noopener noreferrer">
                                Maxi Tu Yip
                            </a>
                            <img src={diagonalArrow} className="diagonalArrow" alt="diagonalArrow" />
                        </div>
                    </div>
                </div>
                <div className="column">
                    {/* <BasicInfo />
                    <br />
                    <br /> */}
                    <BioAndSocials short={false} />
                </div>
            </div>
        </div>
    );
}

export default About;
