import pianoFlyer from "../images/pianoflyer.png";
import essayFlyer from "../images/essayflyer.png";

function Services() {
    return (
        <div className="Services">
            <div className="column">
                <div className="leftAlignedPhotoRow">
                    <img src={essayFlyer} className="flyer" alt="flyer" />
                    <img src={pianoFlyer} className="flyer" alt="flyer" />
                </div>
            </div>
        </div>
    );
}

export default Services;
