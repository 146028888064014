import "../styles/App.css";
import rightCaret from "../icons/right-caret.svg";

function TimelineComponent() {
    return (
        <div>
            {/* 2025 Achievements */}
            <div className="topAlignedRow">
                <img src={rightCaret} className="topAlignedCaret" alt="caret" />
                <text>2025</text>
                <text className="afterCaret">Awarded scholarship to attend Bread Loaf Environmental Writers' Conference.</text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">Awarded scholarship to attend Kenyon Review Writers Workshop.</text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">Awarded scholarship to attend New York Writers Workshop in Greece.</text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">
                    Graduated from{" "}
                    <a className="link" href="https://as.nyu.edu/departments/cwp.html" target="_blank" rel="noopener noreferrer">
                        NYU Creative Writing Program
                    </a>{" "}
                    with an MFA in Creative Writing &#40;thesis advised by Jeffrey Eugenides&#41;.
                </text>
            </div>
            <br />

            {/* 2024 Achievements */}
            <div className="topAlignedRow">
                <img src={rightCaret} className="topAlignedCaret" alt="caret" />
                <text>2024</text>
                <text className="afterCaret">
                    Served as Fiction Editor of <i>Washington Square Review</i>, curating stories for Issues{" "}
                    <a className="link" href="https://www.washingtonsquarereview.com/issue-51" target="_blank" rel="noopener noreferrer">
                        51
                    </a>{" "}
                    and{" "}
                    <a className="link" href="https://www.washingtonsquarereview.com/issue-52" target="_blank" rel="noopener noreferrer">
                        52
                    </a>
                    .
                </text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">
                    Worked in content marketing as a Senior Editor for Toptal's{" "}
                    <a className="link" href="https://www.toptal.com/developers/blog" target="_blank" rel="noopener noreferrer">
                        engineering blog
                    </a>
                    .
                </text>
            </div>
            <br />

            {/* 2023 Achievements */}
            <div className="topAlignedRow">
                <img src={rightCaret} className="topAlignedCaret" alt="caret" />
                <text>2023</text>
                <text className="afterCaret">
                    Longlisted for the <i>A Public Space</i> Writing Fellowship.
                </text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">Taught undergraduate course "Introduction to Creative Writing" as adjunct professor in the English Department at NYU.</text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">
                    Served as Books Editor of <i>Washington Square Review</i>, curating content for{" "}
                    <a className="link" href="https://www.washingtonsquarereview.com/online-exclusives" target="_blank" rel="noopener noreferrer">
                        Online Exclusives
                    </a>
                    .
                </text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">Awarded NYU GSAS Master's College Research Grant.</text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">Awarded NYU Dean's Conference Fund.</text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">
                    Taught private{" "}
                    <a className="link" href="https://amyyqlin.com/services" rel="noopener noreferrer">
                        piano lessons
                    </a>
                    .
                </text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">
                    Edited{" "}
                    <a className="link" href="https://amyyqlin.com/services" rel="noopener noreferrer">
                        college admissions essays
                    </a>{" "}
                    as a freelancer.
                </text>
            </div>
            <br />

            {/* 2022 Achievements */}
            <div className="topAlignedRow">
                <img src={rightCaret} className="topAlignedCaret" alt="caret" />
                <text>2022</text>
                <text className="afterCaret">
                    Published debut{" "}
                    <a className="link" href="https://magazine.catapult.co/fiction/stories/amy-y-q-lin-fiction-short-story-a-precious-stone" target="_blank" rel="noopener noreferrer">
                        short story
                    </a>{" "}
                    in <i>Catapult</i>.
                </text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">
                    Story was a semi-finalist in the <i>Sewanee Review</i> fiction contest.
                </text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">
                    Awarded scholarship to attend{" "}
                    <a className="link" href="https://as.nyu.edu/departments/cwp.html" target="_blank" rel="noopener noreferrer">
                        NYU Creative Writing Program
                    </a>
                    .
                </text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">Attended Tin House Workshop.</text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">Attended Sewanee Writers' Conference.</text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">Left Microsoft.</text>
            </div>
            <br />

            {/* 2020 */}
            <div className="topAlignedRow">
                <img src={rightCaret} className="topAlignedCaret" alt="caret" />
                <text>2020</text>
                <text className="afterCaret">
                    Developed a{" "}
                    <a
                        className="link"
                        href="https://www.linkedin.com/posts/amyliny_diversityandinclusion-diversityintech-publicspeaker-activity-6836275441779777536-ECPj"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        talk
                    </a>{" "}
                    about the Asian American experience and presented internationally to over 1,000 attendees, during the rise of anti-Asian sentiment.
                </text>
            </div>
            <br />

            {/* 2018 */}
            <div className="topAlignedRow">
                <img src={rightCaret} className="topAlignedCaret" alt="caret" />
                <text>2018</text>
                <text className="afterCaret">
                    Won BOOM &#40;Bits on Our Minds&#41; cutting-edge technology showcase with{" "}
                    <a
                        className="link"
                        href="https://www.linkedin.com/posts/amyliny_i-am-pleased-honored-and-humbled-to-accept-activity-6392881639453659137-T6u4"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Bananagrams AI solver
                    </a>
                    .
                </text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">
                    Attended Game Developers Conference &#40;GDC&#41; as an International Game Developers Association &#40;IGDA&#41; Scholar and Girls Make Games Scholar.
                </text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">Started working at Microsoft on Xbox Game Pass subscription and apps.</text>
            </div>
            <br />

            {/* 2017 */}
            <div className="topAlignedRow">
                <img src={rightCaret} className="topAlignedCaret" alt="caret" />
                <text>2017</text>
                <text className="afterCaret">
                    Won Riot Games Hackathon with League of Legends{" "}
                    <a className="link" href="https://www.youtube.com/watch?v=A59IFDQu2Aw" target="_blank" rel="noopener noreferrer">
                        immersive theater VR simulation
                    </a>
                    .
                </text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">Awarded International Women's Day Leadership Award.</text>
            </div>
            <div className="topAlignedRow">
                <text className="afterInvisibleCaret">Streamed gameplay on Twitch with over 2,200 followers and 100 concurrent viewers.</text>
            </div>
            <br />
        </div>
    );
}

export default TimelineComponent;
