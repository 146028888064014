import "../styles/App.css";
import useWindowDimensions from "../helpers";
interface props {
    short: boolean;
}

const Bio = (props: props) => {
    const { short } = props;
    const width = useWindowDimensions().width;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const rotatingQuote = (
        <p>
            Sometimes, I wonder if I would know a beautiful thing
            <br />
            if I saw it. So often, I was miserable when I was young,
            <br />
            even in California with the ocean close and fat seals
            <br />
            munching flatfish, tonguing urchins in their molars,
            <br />
            sunning themselves pink by the sandy primrose. I ignored
            <br />
            the whistle of the rock-faced mountain and her chorus
            <br />
            of dry hills, walked past the blazing stars and lemons in
            <br />
            dramatic ripe. I was so sad out west. The truth is I am
            <br />
            most exquisite on the east coast, meaning I am in rhythm.
            <br />
            I do not track the world by beauty but joy. That first bite
            <br />
            into the soft carrot of tagine stew while a storm wailed
            <br />
            over the East River. The misfit raccoon bouncing on
            <br />
            trash bins in Central Park after we saw a Japanese play.
            <br />
            We almost crashed a wedding that night at the Boathouse
            <br />
            but lost our nerve. We were not dressed for the caper,
            <br />
            but even this felt like rogue joy. Yes. It was joy, wasn't it?
            <br />
            Even if it was ugly, it was joy.
            <br />
            <br />
            <div className="rowEnd">-Megan Fernandes, "Love Poem"</div>
        </p>
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const rotatingQuoteMobileSize = (
        <p>
            Sometimes, I wonder if I would know a beautiful thing / if I saw it. So often, I was miserable when I was young, / even in California with the ocean close and fat seals / munching
            flatfish, tonguing urchins in their molars, / sunning themselves pink by the sandy primrose. I ignored / the whistle of the rock-faced mountain and her chorus / of dry hills, walked past
            the blazing stars and lemons in / dramatic ripe. I was so sad out west. The truth is I am / most exquisite on the east coast, meaning I am in rhythm. / I do not track the world by beauty
            but joy. That first bite / into the soft carrot of tagine stew while a storm wailed / over the East River. The misfit raccoon bouncing on / trash bins in Central Park after we saw a
            Japanese play. / We almost crashed a wedding that night at the Boathouse / but lost our nerve. We were not dressed for the caper, / but even this felt like rogue joy. Yes. It was joy,
            wasn't it? / Even if it was ugly, it was joy.
            <br />
            <br />
            <div className="rowEnd">-Megan Fernandes, "Love Poem"</div>
        </p>
    );

    const shortBio = (
        <p>
            Amy Y.Q. Lin is a Chinese American writer. She writes literary and speculative fiction, drawing upon her experience as a software engineer. Her debut story can be found in <i>Catapult</i>,
            and her other work, in <i>The Rumpus</i> and <i>One Story</i>'s blog. Her writing was a semi-finalist for the 2022 <i>Sewanee Review</i> fiction contest and has been supported by Tin House
            and the Sewanee Writers' Conference. An MFA graduate of the NYU Creative Writing Program, she has served as Fiction Editor for <i>Washington Square Review</i> and a reader for{" "}
            <i>One Story</i>. She lives in Seattle, where she is at work on her first novel.
        </p>
    );

    return (
        <div>
            {short ? (
                width < 1300 ? (
                    <div>
                        {shortBio}
                        {/* {rotatingQuoteMobileSize} */}
                    </div>
                ) : (
                    <div>
                        {shortBio}
                        {/* {rotatingQuote} */}
                    </div>
                )
            ) : (
                <text>
                    <div className="paragraph">
                        Amy Y.Q. Lin is a Chinese American writer. She writes literary and speculative fiction, drawing upon her experience as a software engineer. Her debut story can be found in{" "}
                        <i>Catapult</i>, and her other work, in <i>The Rumpus</i> and <i>One Story</i>'s blog. Her writing was a semi-finalist for the 2022 <i>Sewanee Review</i> fiction contest and
                        has been supported by Tin House and the Sewanee Writers' Conference. She lives in Seattle, where she is at work on her first novel.
                    </div>
                    <div className="paragraph">
                        She received her MFA in Creative Writing in 2025 from New York University, where she studied the craft of fiction with the greats: Jeffrey Eugenides, Garth Greenwell, Katie
                        Kitamura, Raven Leilani, David Lipsky, Jonas Hassen Khemiri, and Hannah Tinti.
                    </div>
                    <div className="paragraph">
                        At NYU, Amy was an adjunct professor in the English department. She recently taught an undergraduate course called "Introduction to Prose and Poetry," a combined creative
                        writing workshop and craft class, to students of various disciplines and ages. She developed her syllabus (available upon request) around international 20th and 21st century
                        writers and included a broad range of poetic forms and literary subgenres. By the end of the semester, students came away with a strong body of work for their writing
                        portfolio, an new understanding of storytelling techniques, and the tools to critique future creative works with respect and professionalism. She has also taught classical
                        piano repertoire and technique from her private studio and freelanced as a college admissions essay consultant.
                    </div>
                    <div className="paragraph">
                        In addition to her teaching expertise, Amy brings a wealth of experience in publishing, content marketing, and editorial roles: she was recently Senior Editor at a tech
                        startup, authoring and editing articles for a highly regarded software development blog. Amy has served as Fiction Editor and Books Editor for the{" "}
                        <i>Washington Square Review</i>, an award-winning literary magazine, where she managed a team of two editors and three assistant editors, solicited writers, curated short
                        stories and book reviews for two print issues and the digital features section, and managed content reading, selection, assignment, editing, and publication schedules. She has
                        also been a reader for <i>One Story</i>.
                    </div>
                    <div className="paragraph">
                        Prior to writing, Amy was a software engineer in the video games industry, launching the Xbox Game Pass subscription and Xbox apps on PC and mobile. During her time at
                        Microsoft, she drove performance improvements that significantly reduced app startup time, spearheaded a weekly livestream on the official Xbox channel on Twitch, and led DEI
                        initiatives for Early in Career and AAPI communities. Amy also launched major revenue-driving experiences, including cloud streaming, third-party integrations with Ubisoft and
                        EA, a new family subscription type, and the global expansion of Game Pass to Southeast Asian markets. She has engineering experience in web, PC and mobile app development using
                        React, React Native, TypeScript, and Redux. She received her B.A. in Computer Science from Cornell University.
                    </div>
                    <div className="paragraph">
                        On the side, Amy has developed and published personal projects in artificial intelligence and virtual reality, including award-winning simulations, adversarial bots, and video
                        games. At the Riot Games Hackathon in 2017, her team's League of Legends home theater simulation won first place as a fresh visualization tool for advancing VR streaming
                        entertainment. In 2017, Amy also created an AI solver to play Bananagrams and win the game faster than human opponents via the application of game tree search, randomized
                        algorithms, and hill climbing. Her project received the Workday-sponsored award at Cornell University's cutting-edge technology showcase, BITS ON OUR MINDS (BOOM) 2018. In 2018
                        and 2016, Amy designed, engineered, and created the soundtrack for two games, Direkt and Fara Heim, both action puzzlers that she and the team evolved from player feedback and
                        data analytics to improve level progression and player retention. In college, she worked in a cross-functional project team of mechanical engineers, electrical engineers, and
                        software engineers on the development of a Star Wars rollercoaster VR simulation that integrated with a Moog motion platform, physical interactive models, and mobile app
                        controllers to provide sensory feedback for real-world performance prediction.
                    </div>
                    <div className="paragraph">Once upon a time, she was also a professional video game streamer.</div>
                </text>
            )}
        </div>
    );
};

export default Bio;
