import React from "react";

function Resume() {
    return (
        <div className="Resume">
            <div className="column">
                <iframe src="/AmyLinFang_CV_March2025.pdf#view=FitH" title="cv" className="pdf" />
                {/* Other options: */}
                {/* <embed src="/AmyLinFang_CV_March2025.pdf" type="application/pdf" className="pdf" />; */}
                {/* <object data="/AmyLinFang_CV_March2025.pdf" type="application/pdf" className="pdf">
                    <p>
                        Your browser doesn't support PDFs. Please download the PDF to view it: <a href="/AmyLinFang_CV_March2025.pdf">Download PDF</a>.
                    </p>
                </object> */}
            </div>
        </div>
    );
}

export default Resume;
